import { combineReducers } from 'redux';

import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import activitiesReducer from './components/activitiesPageData';
import dealsReducer from './components/dealsPageData';
import userReducer from './components/loginPageData';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  activities: activitiesReducer,
  pipelines: dealsReducer,
  user: userReducer,
});

export default reducers;
