/* eslint-disable no-underscore-dangle */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';
import api from './middleware/api';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export default function configureMyStore(initialState) {
  const store = configureStore({
    reducer: reducers,
    initialState,
    middleware: [...getDefaultMiddleware(), ...middlewares, api],
  });

  sagaMiddleware.run(sagas);

  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     // eslint-disable-next-line global-require
  //     const nextRootReducer = require('./reducers');
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
}
