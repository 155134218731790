/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'activitiesPageData',
  initialState: {
    activities: [],
    deals: [],
    loading: false,
    lastFetch: Date.now(),
  },
  reducers: {
    activitiesRequested: (activities) => {
      activities.loading = true;
    },
    activitiesRequestFailed: (activities) => {
      activities.loading = false;
    },
    activitiesReceived: (activities, action) => {
      activities.activities = action.payload;
      activities.loading = false;
    },
    activityAdded: (activities, action) => {
      activities.activities.push(action.payload);
    },
    // activityDeleted: (activities, action) => {
    //   activities.activities.splice(action.payload.index, 1);
    // },
  },
});

export default slice.reducer;
const {
  activitiesRequested,
  activitiesRequestFailed,
  activitiesReceived,
  activityAdded,
} = slice.actions;

const url = '/activities';

export const loadActivities = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.activities;

  const diffInMinutes = moment(moment(lastFetch), 'minutes');

  if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url,
      onStart: activitiesRequested.type,
      onSuccess: activitiesReceived.type,
      onError: activitiesRequestFailed.type,
    })
  );
};

export const addActivity = (activity) =>
  apiCallBegan({
    url,
    method: 'post',
    data: { activity, type: 'activity' },
    onSuccess: activityAdded.type,
  });
