/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'LoginPageData',
  initialState: {
    user: { user_email: '', password: '' },
    error: '',
    loading: false,
    lastFetch: Date.now(),
    userFound: false,
    loggedIn: false,
  },
  reducers: {
    userRequested: (user) => {
      user.loading = true;
    },
    userRequestFailed: (user) => {
      user.loading = false;
    },
    userReceived: (user, action) => {
      if (action.payload.status === 'success') {
        user.user = action.payload.user;
        user.userFound = true;
        user.error = '';
      } else {
        user.user = { user_email: '', password: '' };
        user.userFound = false;
        user.error = action.payload.msg;
      }
      user.loading = false;
    },
    userLoggedin: (user) => {
      user.loggedIn = true;
    },
  },
});

export default slice.reducer;
const {
  userRequested,
  userRequestFailed,
  userReceived,
  userLoggedin,
} = slice.actions;

const url = '/users';

export const getUser = (user) =>
  apiCallBegan({
    url: `${url}/login`,
    method: 'post',
    data: user,
    onSuccess: userReceived.type,
    onError: userRequestFailed.type,
    onStart: userRequested.type,
  });

export const loginUser = () => userLoggedin();
