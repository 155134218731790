/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'dealsPageData',
  initialState: {
    pipelines: [],
    selectedPipeline: { pipeline_name: 'Pipeline', pipeline_id: 4444 },
    deals: [],
    stages: [],
    loading: false,
    lastFetch: Date.now(),
    allDeals: [],
    selectedMenuBtnId: '1',
  },
  reducers: {
    pipelineAdded: (pipelines, action) => {
      pipelines.pipelines.push(action.payload);
    },
    pipelineEdited: (pipelines, action) => {
      const { pipeline_name, stages, pipeline_id } = action.payload;
      pipelines.stages = stages;
      pipelines.selectedPipeline = { pipeline_name, pipeline_id };
    },
    pipelinesRequested: (pipelines) => {
      pipelines.loading = true;
    },
    dealsAndStagesRequested: (pipelines) => {
      pipelines.loading = true;
    },
    requestFailed: (pipelines) => {
      pipelines.loading = false;
    },
    pipelinesReceived: (pipelines, action) => {
      pipelines.pipelines = action.payload;
      pipelines.loading = false;
    },
    dealsAndStagesReceived: (pipelines, action) => {
      pipelines.deals = action.payload[0].deals;
      pipelines.stages = action.payload[0].stages;
      pipelines.selectedPipeline = {
        pipeline_id: action.payload[0].pipeline_id,
        pipeline_name: action.payload[0].pipeline_name,
      };
    },
    dealAdded: (pipelines, action) => {
      console.log(action.payload);
      if (
        parseInt(action.payload.pipeline.pipeline_id, 10) ===
        parseInt(pipelines.selectedPipeline.pipeline_id, 10)
      ) {
        pipelines.deals.push(action.payload.deal);
      }
    },
    allDealsRequested: (pipelines) => {
      pipelines.loading = true;
    },
    allDealsAdded: (pipelines, action) => {
      pipelines.allDeals = action.payload.map((d) => ({
        ...d,
        popoverOpen: false,
      }));
      pipelines.loading = false;
    },
    selectedMenuBtnChanged: (pipelines, action) => {
      pipelines.selectedMenuBtnId = action.payload;
    },
    dealFieldEdited: (pipelines, action) => {
      pipelines.allDeals = pipelines.allDeals.map((d) =>
        d.deal_id === action.payload.deal_id
          ? {
              ...d,
              [action.payload.name]:
                typeof action.payload.value === 'object'
                  ? { ...d[action.payload.name], ...action.payload.value }
                  : action.payload.value,
            }
          : d
      );
      if (
        pipelines.selectedPipeline.pipeline_id === action.payload.pipeline_id
      ) {
        pipelines.deals = pipelines.deals.map((d) =>
          d.deal_id === action.payload.deal_id
            ? {
                ...d,
                [action.payload.name]:
                  typeof action.payload.value === 'object'
                    ? { ...d[action.payload.name], ...action.payload.value }
                    : action.payload.value,
              }
            : d
        );
      }
    },
  },
});

export default slice.reducer;
const {
  allDealsAdded,
  allDealsRequested,
  pipelineAdded,
  pipelinesReceived,
  pipelinesRequested,
  dealsAndStagesReceived,
  dealsAndStagesRequested,
  requestFailed,
  dealAdded,
  pipelineEdited,
  selectedMenuBtnChanged,
  dealFieldEdited,
} = slice.actions;

const url = 'api/pipelines';

export const editDealField = (obj) =>
  apiCallBegan({
    url,
    method: 'post',
    onSuccess: dealFieldEdited.type,
    data: { obj, type: 'dealfieldedit' },
  });

export const loadDealsAndStages = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: 'patch',
    onStart: dealsAndStagesRequested.type,
    onSuccess: dealsAndStagesReceived.type,
    onError: requestFailed.type,
  });

export const changeSelectedMenuBtn = (id) => ({
  type: selectedMenuBtnChanged.type,
  payload: id,
});

export const loadPipelines = () => (dispatch, getState) => {
  const { lastFetch } = getState().pipelines;

  const diffInMinutes = moment(moment(lastFetch), 'minutes');

  if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url,
      onStart: pipelinesRequested.type,
      onSuccess: pipelinesReceived.type,
      onError: requestFailed.type,
    })
  );
};

export const loadAllDeals = () =>
  apiCallBegan({
    url: `${url}/dealsall`,
    onStart: allDealsRequested.type,
    onError: requestFailed.type,
    onSuccess: allDealsAdded.type,
  });

export const addpipeline = (pipeline) =>
  apiCallBegan({
    url,
    method: 'post',
    data: { pipeline, type: 'newpipeline' },
    onSuccess: pipelineAdded.type,
  });

export const editpipeline = (pipeline) =>
  apiCallBegan({
    url,
    method: 'post',
    data: { pipeline, type: 'editpipeline' },
    onSuccess: pipelineEdited.type,
  });

export const adddeal = (deal) =>
  apiCallBegan({
    url,
    method: 'post',
    data: { deal, type: 'deal' },
    onSuccess: dealAdded.type,
  });
