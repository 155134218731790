// export const BASE_URL = 'http://167.71.237.97/api/v1/'; // development url
// export const BASE_URL_SOCKET = 'http://167.71.237.97/'; // development url
// export const BASE_URL = 'http://localhost:5000/api/v1/'; // test url
// export const BASE_URL_SOCKET = 'http://localhost:5000/'; // test url
export const BASE_URL = 'https://testcrmapi.quadbtech.com/api/v1/'; // main url
export const BASE_URL_SOCKET = 'https://testcrmapi.quadbtech.com/'; // main url
// export const BASE_URL = 'https://crmapi.quadbtech.com/api/v1/'; // prod url
// export const BASE_URL_SOCKET = 'https://crmapi.quadbtech.com/'; // prod url
export const LOGIN_URL = 'users/login'; // development url for user login
export const REGISTER_URL = 'users/register'; // development url for user registration
export const GETUSERDETAILS_URL = 'users/getUserDetails'; // development url for retrieving user details

export const REGISTER_2FA_URL = 'users/register2FA'; // development url for 2FA registration
export const VALIDATE_2FA_URL = 'users/validate2FA'; // development url for 2FA validation
export const VERIFY_2FA_URL = 'users/verify2FA'; // development url for 2FA verification

export const DELETEPIPELINE_URL = 'dashboard/deletePipeline'; // development url for deleting pipeline by pipeline_id
export const LOADPIPELINES_URL = 'dashboard/getPipelinesByAccount'; // development url for loading pipelines according to the user
export const LOADDEALSTAGE_URL = 'dashboard/getAllDealStageByPID'; // development url for loading deals and stages according to the pipeline
export const LOADALLDEALS_URL = 'dashboard/getAllDealsByAID'; // development url for loading deals and stages according to the pipeline
export const ADDDEAL_URL = 'dashboard/addDeal'; // development url for adding a deal
export const EDITDEAL_URL = 'dashboard/editDeal'; // development url for editing a deal
export const LOADACTIVITIES_URL = 'dashboard/getAllActivitiesByAID'; // development url for loading activities
export const ADDACTIVITY_URL = 'dashboard/addActivity'; // development url for adding an activity
export const ADDPIPELINE_URL = 'dashboard/addPipeline'; // development url for adding a pipeline
export const EDITPIPELINE_URL = 'dashboard/editPipeline'; // development url for editing a pipeline
export const GETDEALBYDID_URL = 'dashboard/getDealByDID'; // development url for loading a deal according to the id
export const GETDEALBYPID_URL = 'dashboard/getAllDealsByPID';
export const GETDEALBYOID_URL = 'dashboard/getAllDealsByOID';
export const GETSTAGEBYSID_URL = 'dashboard/getStageBySID'; // development url for loading a stage according to the id
export const GETPIPELINEBYPID_URL = 'dashboard/getPipelineByPID'; // development url for loadinag a pipeline according to the id
export const ADDDETAIL_URL = 'dashboard/addDetail'; // development url for adding detail in a deal
export const GETDETAILSBYAIDANDTYPE_URL = 'dashboard/getDetailsByAIDAndType'; // development url for adding an activity
export const EDITDETAIL_URL = 'dashboard/editDetail'; // development url for editing a detail
export const LOGACTION_URL = 'dashboard/logAction'; // development url for logging action of a deal
export const GETLOGBYPID_URL = 'dashboard/getLogsByPID'; // development url for loading the logs of a deal

export const ADDUPDATEDETAILVALUES_URL = 'dashboard/addUpdateDetailValues'; // development url for adding a value to detail in a deal
export const DELETEDETAIL_URL = 'dashboard/deleteDetail'; // development url for deleting a detail
export const GETDETAILVALUES_URL = 'dashboard/getDetailValuesByPID'; // development url for fetching all detail values in a deal
export const ADDUPDATEALLDETAILVALUES_URL = 'dashboard/addUpdateAllDetailValue'; // development url for adding/updating all detail values in a deal

export const ADDNOTE_URL = 'dashboard/addNote'; // development url for adding/updating notes in a deal
export const ADDLABEL_URL = 'dashboard/addLabel'; // development url for adding label in a deal
export const GETLABELS_URL = 'dashboard/getAllLabelsByLtAndAID'; // development url for loading all labels in a deal
export const EDITLABEL_URL = 'dashboard/editLabel'; // development url for editing a label in a deal
export const GETLABELBYLID_URL = 'dashboard/getLabelByLID'; // development url for loading label according to id

export const GETALLACTIVITIESBYDID_URL = 'dashboard/getAllActivitiesByDID'; // development url for getting activity by deal_id
export const GETALLACTIVITIESBYOID_URL = 'dashboard/getAllActivitiesByOID'; // development url for getting activity by organization_id
export const GETALLACTIVITIESBYPID_URL = 'dashboard/getAllActivitiesByPID'; // development url for getting activity by person_id
export const EDITACTIVITY_URL = 'dashboard/editActivity'; // development url for editing values of an acitvity
export const DELETEACTIVITY_URL = 'dashboard/deleteActivities';
export const GETNOTE_URL = 'dashboard/getNoteByNID'; // development url for getting note by note_id
export const EDITNOTE_URL = 'dashboard/editNote'; // development url for editing note by note_id
export const GETFILE_URL = 'dashboard/getFileByFID'; // development url for getting file by file_id
export const GETFILEINFO_URL = 'dashboard/getFileInfoByFID'; // development url for getting file info by file_id

export const STOREFILE_URL = 'dashboard/storeFile'; // development url for saving uploaded file
export const GETALLCURRENCIES_URL = 'dashboard/getAllCurrencies'; // development url for retrieving the list of all currencies
export const GETCURRENCYBYCID_URL = 'dashboard/getCurrencyByCID'; // development url for retrieving a particular currency
export const GETCURRENCIESOBJECT_URL = 'dashboard/getAllCurrenciesObj'; // development url for retrieving a particular currency
export const ADDORGANISATION_URL = 'dashboard/addOrganisation'; // development url for adding organisation
export const GETALLORGANISATIONS_URL = 'dashboard/getAllOrganisationByAID'; // development url for getting all organisations
export const GETALLPERSONS_URL = 'dashboard/getAllPersonsByAID'; // development url for getting all persons
export const GETALLORGANISATIONSOBJECT_URL =
  'dashboard/getAllOrganisationByAID'; // development url for getting all organisations object

export const GETORGANIZATIONBYOID_URL = 'dashboard/getOrganisationByOID'; // development url for getting file info by file_id
export const GETPERSONBYPID_URL = 'dashboard/getPersonByPID'; // development url for getting file info by file_id
export const EDITORGANISATION_URL = 'dashboard/editOrganisation'; // development url for editing organisation fields
export const ADDPERSON_URL = 'dashboard/addPerson'; // development url for adding person
export const GETALLPERSONSBYAID_URL = 'dashboard/getAllPersonsByAID'; // development url for loading all persons
export const GETALLPHONEBYAID_URL = 'dashboard/getAllPhoneByAID'; // development url for loading all phone related to particular account
export const GETALLEMAILBYAID_URL = 'dashboard/getAllEmailByAID'; // development url for loading all email related to particular account

export const GETALLNOTIFICATIONS_URL = 'dashboard/getAllNotificationsByAID'; // development url for loading all notifications for an account
export const SENDINVITES_URL = 'users/sendInvites'; // development url for sending invites
export const CHECKINVITE_URL = 'users/checkInvite'; // development url for checking invite code
export const VERIFYOTP_URL = 'users/verifyOTP'; // development url for verifying OTP
export const ADDUSERTOACCOUNTBYINVITE_URL =
  'users/addUserToAccountThroughInvite'; // development url for adding user to account through invite
export const ADDLEAD_URL = 'dashboard/addLead'; // development url for adding lead
export const GETALLLEADSBYAID_URL = 'dashboard/getAllLeadsByAID'; // development url for loading all leads
export const GETLEADBYLID_URL = 'dashboard/getLeadByLID'; // development url for loading lead according to id
export const ADDPRODUCT_URL = 'dashboard/addProduct'; // development url for adding product
export const GETALLPRODUCTSBYAID_URL = 'dashboard/getAllProductByAID'; // development url for loading all products
export const GETACTIVEUSERS_URL = 'users/getActiveUsersByAID'; // development url for retrieving active users in an account
export const GETINVITEDUSERS_URL = 'users/getInvitedUsersByAID'; // development url for retrieving invited users of an account
export const GETUSERPERMISSIONS_URL = 'users/getUserPermissions'; // development url for retrieving user permissions for a particular account
export const EDITPERMISSION_URL = 'users/editPermission'; // development url for editing a permission set for a particular account

export const GETUSERBYUID_URL = 'users/getUserByUID'; // development url for retrieving a user by user_id

export const CHANGEUSERTYPEFORACCOUNT_URL =
  'dashboard/changeUserTypeForAccount';
export const UPDATENOTIFICATION_URL = 'dashboard/updateNotification';
export const DELETENOTIFICATION_URL = 'dashboard/deleteNotificationbyNID';
export const GET_USER_NAMES = 'users/getallUserName'; // development url for getting usernames
export const GETALLUSERSBY_ACCOUNTID = 'users/getallUserbyAID';
export const GETDEALSBYUID = 'dashboard/getdealsbyUID';
export const DELETEDEAL_URL = 'dashboard/deleteDeal';
export const GETPIPELINESBYUSER = 'dashboard/getAllPipelinesByUser';
export const UPADATEPIPELINEACCESS = 'dashboard/updatePipelinesAccess';
export const GETPRESENTUID = 'users/getpresentUID';
export const GETDEALSBYPIPELINEID = 'dashboard/getalldealsByPipelineids';
export const CHECKDEALACTIVITYURL = 'dashboard/checkdealsactivity';
export const GETACTIVITIESBYAID = 'dashboard/getActivityByAID'; // url for fetching activities using activity ID
export const GETNUMOFLOSTDEALSBYAID = 'dashboard/getlostdealsnumberinAccount';
